<template>
  <div>
    <v-form>
      <p class="mb-3">Determine the <b>FALSE</b> statements concerning this experiment.</p>

      <p v-for="option in optionsShuffled" :key="'pt-1-' + option.value" class="my-0 pl-6">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="pl-0 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemMcMPL4_Q9',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {
          text: 'The system is kept at $0\\,^\\circ\\text{C}$ for the duration of the reaction.',
          value: 'a',
        },
        {
          text: 'This reaction is taking place at constant pressure.',
          value: 'b',
        },
        {
          text: 'The volume of water decreases upon melting.',
          value: 'c',
        },
        {
          text: 'The reaction taking place is endothermic.',
          value: 'd',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
